<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-md-12 col-lg-4">
        <!-- 위험형태 목록 -->
        <c-table
          ref="table"
          title="LBL0001248"
          tableId="riskType"
          :columns="grid.columns"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-md-12 col-lg-8">
        <q-form ref="editForm">
          <!-- 위험형태 상세 -->
          <c-card title="LBL0001249" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- 추가 -->
                <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="addData" />
                <!-- 저장 -->
                <c-btn 
                  v-if="saveEditable" 
                  :isSubmit="isSave"
                  :url="saveUrl"
                  :param="riskType"
                  :mappingType="mappingType"
                  label="LBLSAVE" 
                  icon="save" 
                  @beforeAction="saveRiskType"
                  @btnCallback="saveRiskTypeCallback"/>
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <!-- 위험형태 -->
                <c-text
                  :disabled="!saveMode"
                  :editable="editable"
                  :required="true"
                  label="LBL0001026"
                  name="riskTypName"
                  v-model="riskType.riskTypName">
                </c-text>
              </div>
              <div class="col-6">
                <!-- 사용여부 -->
                <c-radio
                  :disabled="!saveMode"
                  :editable="editable"
                  :comboItems="useFlagItems"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="riskType.useFlag">
                </c-radio>
              </div>
              <div class="col-12">
                <!-- 위험형태별 원인 목록 -->
                <c-table
                  ref="causeTable"
                  title="LBL0001250"
                  :columns="gridCasue.columns"
                  :gridHeight="gridCasue.height"
                  :data="riskType.causes"
                  :filtering="false"
                  :columnSetting="false"
                  :usePaging="false"
                  :editable="saveEditable"
                  selection="multiple"
                  rowKey="ramRiskTypeCauseId"
                >
                  <!-- 버튼 영역 -->
                  <template slot="table-button">
                    <q-btn-group outline >
                      <!-- 추가 -->
                      <c-btn v-if="saveEditable" :showLoading="false" label="LBLADD" icon="add" @btnClicked="addCause" />
                      <!-- 제외 -->
                      <c-btn v-if="saveEditable" :showLoading="false" label="LBLEXCEPT" icon="remove" @btnClicked="deleteCause" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'kpsr-guideword',
  data() {
    return {
      searchParam: {
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'riskTypName',
            field: 'riskTypName',
            // 위험형태
            label: 'LBL0001026',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            // 사용여부
            label: 'LBLUSEFLAG',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
      },
      gridCasue: {
        columns: [],
        height: '550px'
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      riskType: {
        ramRiskTypeId: '',  // 위험형태 번호
        riskTypName: '',  // 위험형태명
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        causes: [], // 위험형태 원인 목록
        deleteCauses: [], // [삭제] 위험형태 원인 목록
      },
      editable: true,
      listUrl: '',
      detailUrl: '',
      saveUrl: '',
      saveMode: false,
      isSave: false,
      mappingType: 'POST',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    saveEditable() {
      return this.editable && this.saveMode
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.riskType.list.url
      this.detailUrl = selectConfig.ram.riskType.get.url
      this.saveUrl = transactionConfig.ram.riskType.insert.url
      // code setting
      this.$comm.getComboItems('MDM_PROCESS_TYPE_CD').then(_result => {
        this.gridCasue.columns = [
          {
            name: 'riskTypeCauseMainName',
            field: 'riskTypeCauseMainName',
            // 원인(대분류)
            label: 'LBL0001251',
            align: 'center',
            style: 'width:100px',
            type: 'text',
            sortable: true,
            required: true,
          },
          {
            name: 'riskTypeCauseSubName',
            field: 'riskTypeCauseSubName',
            // 원인(소분류)
            label: 'LBL0001252',
            align: 'left',
            style: 'width:300px',
            type: 'text',
            sortable: true,
            required: true,
          },
          {
            name: 'processTypes',
            field: 'processTypes',
            // 공정구분
            label: 'LBL0001253',
            align: 'center',
            style: 'width:80px',
            type: 'multiSelect',
            isObject: true,
            valueText: 'mdmProcessTypeName',
            valueKey: 'mdmProcessTypeCd',
            itemText: 'codeName',
            itemValue: 'code',
            comboItems: _result,
            sortable: true,
          },
        ];
      });
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    rowClick(row) {
      this.$http.url = this.$format(this.detailUrl, row.ramRiskTypeId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.riskType, _result.data);
        this.saveMode = true;
      },);
    },
    addData() {
      Object.assign(this.$data.riskType, this.$options.data().riskType);
      this.saveMode = true;
    },
    addCause() {
      let date = new Date();
      if (!this.riskType.causes) {
        this.riskType.causes = [];
      }
      this.riskType.causes.push({
        ramRiskTypeCauseId: String(date.getTime()),  // 위험형태원인 번호
        ramRiskTypeId: '',  // 위험형태 번호
        riskTypeCauseMainName: '',  // 위험형태원인명- 대분류
        riskTypeCauseSubName: '',  // 위험형태원인명- 소분류
        processTypes: [],
        editFlag: 'C'
      })
    },
    deleteCause() {
      let selectData = this.$refs['causeTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.riskType.deleteCauses) {
          this.riskType.deleteCauses = [];
        }
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.riskType.deleteCauses, { ramRiskTypeCauseId: item.ramRiskTypeCauseId }) === -1 
            && item.editFlag !== 'C') {
            this.riskType.deleteCauses.push(item)
          }
          this.riskType.causes = this.$_.reject(this.riskType.causes, { ramRiskTypeCauseId: item.ramRiskTypeCauseId })
        })
        this.$refs['causeTable'].$refs['compo-table'].clearSelection();
      }
    },
    saveRiskType() {
      if (this.riskType.ramRiskTypeId) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.ram.riskType.update.url
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.ram.riskType.insert.url
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          let saving = true;
          if (this.riskType.causes 
            && this.riskType.causes.length > 0) {
            saving = this.$comm.validTable(this.gridCasue.columns, this.riskType.causes);
          }
          if (saving) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: 'MSGSAVE', // 저장하시겠습니까?
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.riskType.regUserId = this.$store.getters.user.userId
                this.riskType.chgUserId = this.$store.getters.user.userId
                
                this.isSave = !this.isSave
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveRiskTypeCallback(result) {
      this.riskType.ramRiskTypeId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
      this.rowClick({ ramRiskTypeId: result.data });
    },
  }
};
</script>
